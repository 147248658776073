<template>
  <div class="list">
    <span class="headerBox">
      <span class="searchBox">
        <md-field
          class="searchInput md-layout-item md-size-45 md-medium-size-50 md-xsmall-size-100"
        >
          <label for="pesquisaBooks"> Pesquisar por Box </label>
          <md-input
            id="pesquisaBooks"
            v-model="pesquisaBox"
            @input="filtraBooks()"
          ></md-input>
          <span class="material-symbols-outlined icon">search</span>
        </md-field>
        <md-progress-spinner
          v-if="liberaSpinner"
          :md-diameter="30"
          :md-stroke="2"
          md-mode="indeterminate"
        >
        </md-progress-spinner>
      </span>
    </span>
    <div v-if="valoresAtributos.length > 0" class="doc-box">
      <div class="item" v-for="box in valoresAtributos" :key="box.Valor">
        <DropdownBox
          :nome="box.Valor"
          :descricao="box.Descricao"
          :tipoPessoa="box.TipoPessoaConsulta"
          :tipoBox="'Box de Atributos'"
        />
      </div>
    </div>
    <div v-if="!liberaSpinner && valoresAtributos.length == 0">
      <md-card class="cardAviso">
        Usuário não possui permissão para visualização de Box de Atributos
      </md-card>
    </div>
  </div>
</template>

<script>
import { produtosStore } from "@/stores/produtos";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";

import { userService } from "@/services";
import { mapWritableState, mapActions } from "pinia";
import DropdownBox from "@/components/Books/DropdownBox";

export default {
  components: {
    DropdownBox,
  },

  props: {},

  data() {
    return {
      valoresAtributos: [],
      valoresAtributosControle: [],
      rota: "Box de Atributos",
      liberaSpinner: false,
      pesquisaBox: "",
      msgErroUsuario: "",
    };
  },

  methods: {
    async recuperaRotas() {
      this.liberaSpinner = true;
      await userService.recuperaRotasUsuario().then(
        (usuario) => {
          usuario.forEach((rota) => {
            if (rota.CodigoRota == "v3/box") {
              rota.PermissoesParametro.forEach((permissao) => {
                this.valoresAtributos = permissao.DominioValores;
              });
            }
          });
        },
        (error) => {
          this.msgErroUsuario = "Erro ao carregar dados do usuário logado.";
        }
      );
      this.valoresAtributosControle = this.valoresAtributos;
      this.liberaSpinner = false;
    },

    filtraBooks() {
      this.valoresAtributos = this.valoresAtributosControle;
      if (this.pesquisaBox != undefined && this.pesquisaBox != "") {
        if (this.pesquisaBox.trim().length > 0) {
          this.valoresAtributos = this.booksFiltrados;
        } else {
          this.valoresAtributos = this.valoresAtributosControle;
        }
      }
    },

    ...mapActions(produtosStore, ["getProdutos"]),
    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },

  computed: {
    ...mapWritableState(produtosStore, {
      produtos: "produtos",
    }),

    booksFiltrados() {
      if (this.pesquisaBox.trim().length > 0) {
        const searchTerm = this.pesquisaBox.toLowerCase();

        return this.valoresAtributos.filter(
          (item) =>
            (item.Valor && item.Valor.toLowerCase().includes(searchTerm)) ||
            (item.Descricao &&
              item.Descricao.toLowerCase().includes(searchTerm))
        );
      }
      return this.valoresAtributos;
    },
  },
  async mounted() {},

  async created() {
    this.liberaSpinner = true;

    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };
    
    this.recebeContexto(contexto);

    await this.getProdutos();
    await this.recuperaRotas();
    this.pesquisaBox = "";

    if (this.$route.params) {
      this.pesquisaBox = this.$route.params.text;
      this.filtraBooks();
    }
    this.liberaSpinner = false;
  },
};
</script>

<style lang="scss" scoped>
.headerBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btn-export {
  display: flex;
  gap: 5px;
  width: 200px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  background: $accent;
  padding: 5px 10px;
  border: 1px solid $bg-sidebar;
  border-radius: 5px;
  transition: 0.3s ease all;
  color: $white;
  font-weight: 600;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  &:active {
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
  }
}

.item {
  overflow: hidden;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 20px;

  @include mediaQuery(medium) {
    max-width: 70%;
    margin: 0 auto;
  }
}

.searchBox {
  display: flex;
  align-items: flex-end;
}

.searchInput {
  margin: 10px 10px 0 0;
  padding: 5px 0;
  border-radius: 20%;
  min-width: 250px !important;
}

.icon {
  font-size: 18px;
  padding: 0;
  height: 18px;
  cursor: default;
}

.doc-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cardAviso {
  width: 90%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
</style>
