<template>
  <div class="list">
    <span class="headerBox">
      <span class="searchBox">
        <md-field
          class="searchInput md-layout-item md-size-45 md-medium-size-50 md-xsmall-size-100"
        >
          <label for="pesquisaBooks">Pesquisar por Book ou Atributo</label>
          <md-input
            id="pesquisaBooks"
            v-model="pesquisaBook"
            @keyup.enter="filtraBooks"
          ></md-input>
        </md-field>
        <button class="button buttonSearch" @click="filtraBooks">
          <span class="material-symbols-outlined">search</span>
        </button>

        <md-progress-spinner
          v-if="liberaLoading"
          :md-diameter="30"
          :md-stroke="2"
          md-mode="indeterminate"
        >
        </md-progress-spinner>
      </span>
      <button class="btn-export" @click="exportarPlanilha">
        <span class="material-symbols-rounded">download</span>
        Exportar planilha
      </button>
    </span>
    <div class="item" v-for="book in books_v3_display" :key="book.Codigo">
      <Dropdown
        :nome="book.Codigo"
        :descricao="book.Descricao"
        :atributos="book.ListaAtributos"
        :parametros="book.ListaParametros"
        :tipoRetorno="book.TipoRetorno"
        :pesquisaBook="pesquisaBookControl"
      ></Dropdown>
    </div>
  </div>
</template>

<script>
import { booksStore } from "@/stores/books";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapWritableState, mapActions } from "pinia";
import Dropdown from "@/components/Books/Dropdown";
import { create } from "@/services/planilha.service.js";
export default {
  components: {
    Dropdown,
  },
  data() {
    return {
      pesquisaBook: "",
      pesquisaBookControl: "",
      books_v3_display: [],
      liberaLoading: false,
    };
  },
  methods: {
    ...mapActions(booksStore, ["getBooksV3"]),

    exportarPlanilha() {
      create(this.books_v3);
    },

    filtraBooks() {
      this.liberaLoading = true;
      this.pesquisaBookControl = "";
      this.books_v3_display = this.books_v3;
      if (this.pesquisaBook != undefined && this.pesquisaBook != "") {
        if (this.pesquisaBook.trim().length > 0) {
          this.books_v3_display = this.booksFiltrados;

          const searchTerm = this.pesquisaBook.toLowerCase();
          const regexMatch = searchTerm.match(/(bk_\d{3}_\d{3})_(\w+)/);
          if (regexMatch == null) {
            this.pesquisaBookControl = this.pesquisaBook;
          }
          if (regexMatch != null) {
            const atrName = regexMatch[2];
            this.pesquisaBookControl = atrName;
          }
        }
      }
      this.liberaLoading = false;
    },

    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },

  async mounted() {
    this.liberaLoading = true;
    this.pesquisaBook = "";

    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };
    
    this.recebeContexto(contexto);

    await this.getBooksV3();
    this.books_v3_display = this.books_v3;

    if (this.$route.params) {
      this.pesquisaBook = this.$route.params.text;
      this.filtraBooks();
    }
    this.liberaLoading = false;
  },

  computed: {
    ...mapWritableState(booksStore, {
      books_v3: "books_v3",
    }),
    booksFiltrados() {
      if (this.pesquisaBook.trim().length > 0) {
        const searchTerm = this.pesquisaBook.toLowerCase();
        const regexMatch = searchTerm.match(/(bk_\d{3}_\d{3})_(\w+)/);

        if (regexMatch == null) {
          return this.books_v3.filter(
            (item) =>
              item.Codigo.toLowerCase().includes(searchTerm) ||
              item.ListaAtributos.some((atributo) =>
                atributo.Identificador.toLowerCase().includes(searchTerm)
              ) ||
              item.Descricao.toLowerCase().includes(searchTerm) ||
              item.ListaAtributos.some((atributo) =>
                atributo.Descricao.toLowerCase().includes(searchTerm)
              )
          );
        }
        if (regexMatch != null) {
          const bkName = regexMatch[1];
          const atrName = regexMatch[2];
          return this.books_v3.filter(
            (item) =>
              item.Codigo.toLowerCase().includes(bkName) &&
              item.ListaAtributos.some((atributo) =>
                atributo.Identificador.toLowerCase().includes(atrName)
              )
          );
        }
      }
      return this.books_v3;
    },
    //bk_101_001_nome
  },
};
</script>

<style lang="scss" scoped>
.headerBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btn-export {
  display: flex;
  gap: 5px;
  width: 200px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  background: $accent;
  padding: 5px 10px;
  border: 1px solid $bg-sidebar;
  border-radius: 5px;
  transition: 0.3s ease all;
  color: $white;
  font-weight: 600;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  &:active {
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
  }
}

.item {
  overflow: hidden;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 20px;

  @include mediaQuery(medium) {
    max-width: 70%;
    margin: 0 auto;
  }
}

.searchBox {
  display: flex;
  align-items: flex-end;
}

.searchInput {
  margin: 10px 10px 0 0;
  padding: 5px 0;
  border-radius: 20%;
  min-width: 250px !important;
}

.buttonSearch {
  width: 40px;
  margin: 0 0 10px 0;
  background-color: transparent;
  border: none;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}

.buttonSearch:hover {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.buttonSearch:active {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1);
}
</style>
