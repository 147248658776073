<template>
  <div
    class="md-layout"
    v-if="!carregando && resultados.Consultas != undefined"
  >
    <div class="md-layout-item md-size-100" id="resultado">
      <md-card v-if="erro" class="conteudo-horizontal-centralizado">
        <md-card-content v-if="this.msgErro != ''">
          {{ this.msgErro }}
        </md-card-content>
        <md-card-content v-if="this.msgErro == ''">
          Não foi possível recuperar as informações no momento. Por favor, tente
          novamente mais tarde.
        </md-card-content>
      </md-card>

      <md-card class="conteudo-horizontal-centralizado" v-if="carregando">
        <md-card-content>
          Carregando...&nbsp;&nbsp;<md-progress-spinner
            :md-diameter="30"
            :md-stroke="2"
            md-mode="indeterminate"
          ></md-progress-spinner>
        </md-card-content>
      </md-card>
      <span v-if="!carregando">
        <div v-if="resultados.Tipo == 'box'">
          <ResultadosHistorico
            v-if="resultados.Consultas"
            :resultados="resultados"
          />
        </div>
        <div v-if="resultados.Tipo == 'superbox'">
          <ResultadosSuperBoxHistorico :resultados="resultados" />
        </div>
        <div v-if="resultados.Tipo == 'modelo'">
          <ResultadosModelosHistorico :resultados="resultados" />
        </div>
      </span>
    </div>
  </div>
</template>
<script>
import { historicoService } from "@/services/historico.service";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { storeAlertasSuperBoxPJ } from "@/stores/superbox-alertas-pj.js";
import { mapActions } from "pinia";

import ResultadosHistorico from "@/components/Outputs/ResultadosHistorico";
import ResultadosSuperBoxHistorico from "@/components/SuperBox/ResultadosSuperBoxHistorico.vue";
import ResultadosModelosHistorico from "@/components/Modelos/ResultadosModelosHistorico.vue";

export default {
  name: "Historico",
  components: {
    ResultadosHistorico,
    ResultadosSuperBoxHistorico,
    ResultadosModelosHistorico,
  },

  data() {
    return {
      resultados: {},
      carregando: false,
      erro: false,
      msgErro: "",
    };
  },
  methods: {
    verificaTipoConsulta() {
      let contaSUP = 0;
      let contaNSUP = 0;
      let contaBox = 0;

      for (const consulta of this.resultados.Consultas) {
        if (consulta.Consulta.erro != null) {
          this.erro = true;
          this.msgErro = consulta.Consulta.erro.erro;
        }

        if (
          consulta.Consulta.sumario_consulta.parametros.cod_ref.startsWith(
            "BOXSUPP"
          )
        ) {
          contaSUP++;
        }
        if (
          !consulta.Consulta.sumario_consulta.parametros.cod_ref.startsWith(
            "BOXSUPP"
          )
        ) {
          contaNSUP++;
        }
        if (
          consulta.Consulta.sumario_consulta.parametros.cod_ref.startsWith(
            "BOXATR"
          )
        ) {
          contaBox++;
        }
      }

      if (contaSUP >= 6) {
        this.resultados.Tipo = "superbox";
        this.erro = false;
      } else if (
        (contaSUP === 0 &&
          contaNSUP >= 1 &&
          this.resultados.Rota == "v3/consulta") ||
        contaBox > 0
      ) {
        this.resultados.Tipo = "box";
        this.erro = false;
      } else if (
        (contaSUP <= 2 && contaNSUP == 1) ||
        (this.resultados.Rota == "v3/modelo" && contaSUP <= 2)
      ) {
        this.resultados.Tipo = "modelo";
        this.erro = false;
      }
    },

    verificaTipoConsultaMetadados() {
      if (this.resultados.TextoMetadados.includes("consulta_superbox")) {
        this.resultados.Tipo = "superbox";
        this.erro = false;
      } else if (this.resultados.TextoMetadados.includes("consulta_box")) {
        this.resultados.Tipo = "box";
        this.erro = false;
      } else if (this.resultados.TextoMetadados.includes("consulta_modelo")) {
        this.resultados.Tipo = "modelo";
        this.erro = false;
      }
    },

    ...mapActions(storeAlertasSuperBox, ["resetAlertasPf"]),
    ...mapActions(storeAlertasSuperBoxPJ, ["resetAlertasPj"]),

  },

  watch: {},
  computed: {},

  async mounted() {
    this.analytics(
      "Histórico detalhada",
      "Acessos",
      "Página detalhada do histórico acessada."
    );
    this.carregando = true;
    this.resetAlertasPf();
    this.resetAlertasPj();

    const objetoCod = {
      codigoAgrupador: this.$route.params.codigo,
    };
    const query = new URLSearchParams(objetoCod);
    this.resultados = await historicoService.recuperaConsulta(query.toString());
    if (!this.resultados) {
      this.erro = true;
    }
    
    if (
      this.resultados.TextoMetadados == undefined ||
      this.resultados.TextoMetadados == null
    ) {
      await this.verificaTipoConsulta();
    } else {
      await this.verificaTipoConsultaMetadados();
    }
    this.carregando = false;
  },
};
</script>
