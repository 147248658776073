<template>
  <div class="width-100">
    <HeaderResultado
      v-if="codigo_agrupador"
      :codigoAgrupador="codigo_agrupador"
    />
    <div class="resultados" v-if="codigo_agrupador">
      <Resultados
        :produtos="produtos"
        :campos="campos"
        :contexto="contexto"
        @addConsulta="comecaNovaConsulta"
        @addRequisitado="addRequisitado"
      />
    </div>
    <div v-else>
      <loading />
    </div>
  </div>
</template>

<script>
import { produtosStore } from "@/stores/produtos";
import { mapActions, mapWritableState } from "pinia";

import Resultados from "@/components/Outputs/Resultados";
import Loading from "../../../components/Loading.vue";
import HeaderResultado from "@/components/Visuais/HeaderResultado";

export default {
  props: {
    produtos: Array,
    campos: Array,
    opcaoInicialSelecionada: String,
    produtos_ciclica: Array,
    contexto: Array,
  },
  data() {
    return {
      show_modal: false,
    };
  },
  components: {
    Resultados,
    Loading,
    HeaderResultado,
  },
  mounted() {
    if (this.contexto.length == 0) {
      this.$router.push("/consultas/box/0");
    }
    this.analytics("Resultados", "Acessos", "Entrou na página de resultados");
  },
  methods: {
    addRequisitado(recomendado, id) {
      //Aqui precisa validar!
      this.show_modal = true;
      this.$emit("addRequisitado", recomendado, id);
    },
    comecaNovaConsulta(obj) {
      this.$emit("addConsulta", obj);
    },
    async gerapdf() {
      this.analytics(
        "Impressão PDF",
        "Utilitários",
        "O usuário gerou um pdf dos resultados obtidos"
      );

      var worker = html2pdf();
      var opt = {
        margin: 0.3,
        filename: `${this.codigo_agrupador}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 1.6,
          allowTaint: true,
          useCors: true,
        },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        pagebreak: { mode: "avoid-all" },
      };

      var cards = document.getElementsByClassName("card");

      var elementToPrint = document.createElement("div");
      elementToPrint.classList.add("html2pdf__page-break");

      var pulaLinha = document.createElement("br");
      cards.forEach((box) => {
        const boxClone = box.cloneNode(true);
        boxClone.classList.add("html2pdf__page-break");
        elementToPrint.appendChild(boxClone.cloneNode(true));
        elementToPrint.appendChild(pulaLinha.cloneNode(true));
      });
      await worker.set(opt).from(elementToPrint).toPdf().save();
    },
  },
  computed: {
    ...mapWritableState(produtosStore, {
      codigo_agrupador: "codigo_agrupador",
    }),

    produtosSelecionadosIndicativo() {
      var produtosSelecionadosNome = {};
      this.produtosSelecionados.forEach((produto) => {
        produtosSelecionadosNome[produto.Box] = true;
      });
      this.produtosSelecionadosCiclica.forEach((produto) => {
        produtosSelecionadosNome[produto.Box] = true;
      });
      return produtosSelecionadosNome;
    },
    produtosSelecionados() {
      return this.produtos.filter((produto) => {
        return produto.Selecionado;
      });
    },
    produtosSelecionadosCiclica() {
      return this.produtos_ciclica.filter((produto) => {
        return produto.Selecionado;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin-top: -5rem;
  display: flex;
  justify-content: right;
  gap: 20px;
  width: 95%;
  padding: 1rem 0;

  @include mediaQueryMax(medium) {
    margin-top: -2.5rem;
  }

  .nova-consulta {
    width: fit-content;
    padding: 10px;
    background: $bg-sidebar;
    color: $white;
    font-weight: 500;
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    border-radius: 15px;

    transition: 0.3s ease all;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0px 4px 5px 0.1px $accent;
    }
  }
}
.resultados {
  padding-left: 20px;
  width: 100%;
}

.width-100 {
  width: 100%;
}
</style>
