<template>
  <div class="campos">
    <CamposConsulta
      data-aos="fade"
      :campos="campos"
      :produtos="produtosSelecionados"
      :ciclica="false"
      :opcaoInicial="opcaoInicialSelecionada"
      @realizaConsulta="preencheInput"
    />
  </div>
</template>

<script>
import CamposConsulta from "@/components/Inputs/CamposConsulta";

export default {
  props: {
    campos: Array,
    produtos: Array,
    opcaoInicialSelecionada: String,
  },
  data() {
    return {
      camposPreenchidos: {},
    };
  },
  components: {
    CamposConsulta,
  },
  methods: {
    preencheInput() {
      this.camposPreenchidos = {};
      var camposPreenchidos_ = this.campos.filter((campo) => campo.Valor);
      this.campos.forEach((campo) => {
        campo.ValorPossivel = campo.Valor;
      });
      camposPreenchidos_.forEach((campoPreenchido) => {
        this.camposPreenchidos[campoPreenchido.Nome.toLowerCase()] =
          campoPreenchido.Valor;
      });
      this.$emit("realizaConsulta", this.camposPreenchidos);
      let control = true;
      this.produtos.forEach((produto) => {
        if (produto.Selecionado == true && control) {
            this.$router.push("/consultas/box/3");
            control = false;
        }
      });
    },
  },
  computed: {
    produtosSelecionados() {
      return this.produtos.filter((produto) => {
        return produto.Selecionado;
      });
    },
  },
  mounted() {
    if (this.produtosSelecionados.length == 0) {
      this.$router.push("/consultas/box/0");
      window.location.reload();
    }
    this.analytics("Campos", "Acessos", "Entrou no preenchimento de campos");
  },
};
</script>

<style scoped>
.campos {
  margin-top: 10%;
  max-width: 70%;
}
</style>
