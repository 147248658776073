<template>
  <div class="view-wrapper" v-if="liberaTela">
    <Steps
      v-if="verificaRotasSteps('normal')"
      :produtos="produtos"
      :campos="campos"
      :opcaoInicialSelecionada="opcaoInicial"
      :contexto="contexto"
    />
    <StepsCiclica
      v-if="verificaRotasSteps('ciclica')"
      :produtos="produtos"
      :campos="campos"
      :opcaoInicialSelecionada="opcaoInicial"
      :contexto="contexto"
    />
    <div class="view">
      <router-view
        @substituiProdutos="substituiProdutos"
        @realizaConsulta="realizaConsulta"
        @selecionaInicial="selecionaInicial"
        @addConsulta="comecaNovaConsulta"
        @substituiProdutosCiclica="substituiProdutosCiclica"
        @realizaConsultaCiclica="realizaConsultaCiclica"
        @addRequisitado="addRequisitado"
        :inferido="inferido"
        :produtos="produtos"
        :campos="campos"
        :opcaoInicialSelecionada="opcaoInicial"
        :produtos_ciclica="produtos_ciclica"
        :campos_ciclica="campos_ciclica"
        :contexto="contexto"
        :codigo_agrupador="codigo_agrupador"
      />
    </div>
  </div>
</template>

<script>
import { consultasService } from "@/services";
import { criaCampos } from "@/helpers";
import jsonProdutos from "@/helpers/produtos.json";
import "aos/dist/aos.css";
import Steps from "@/components/Visuais/Steps.vue";
import StepsCiclica from "@/components/Visuais/StepsCiclica.vue";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";

import { produtosStore } from "@/stores/produtos";
import { userPreferenceStore } from "@/stores/userPreference";

import { mapActions, mapWritableState } from "pinia";
import { sessionStore } from "../../stores/session";
export default {
  components: {
    Steps,
    StepsCiclica,
  },
  data() {
    return {
      liberaTela: false,

      campos: [],
      opcaoInicial: "",
      inferido: {},
      produtos_ciclica: [],
      campos_ciclica: [],
      contexto: [],
    };
  },
  methods: {
    reset() {
      this.contexto = [];
      this.resetaCodigoAgrupador();
      this.produtos.forEach((produto) => {
        produto.Selecionado = false;
      });
      this.campos.forEach((campo) => {
        campo.Visivel = false;
        campo.Disabled = false;
        campo.Requerido = false;
      });
    },
    addRequisitado(recomendado, id) {
      const _contexto = { ...this.contexto[id] };
      _contexto.requisitados[recomendado] = true;
      this.$set(this.contexto, id, _contexto);
      this.$nextTick(() => {
        const box = document.getElementById(recomendado + id);
        const content = document.querySelector(".content");
        content.classList.add("margin-bottom-adapter");
        box.scrollIntoView();
        setTimeout(() => {
          content.classList.remove("margin-bottom-adapter");
        }, 5000);
      });
    },
    verificaRotasSteps(step) {
      var rota = this.$route.fullPath.slice(-1);
      if (step == "normal") {
        if (parseInt(rota) < 3) {
          return true;
        } else {
          return false;
        }
      } else if (step == "ciclica") {
        if (parseInt(rota) > 3) {
          return true;
        } else {
          return false;
        }
      }
    },
    comecaNovaConsulta(inferido) {
      this.inferido = {};
      this.inferido = inferido;
      this.produtos_ciclica = this.produtos.map((produto) => ({ ...produto }));
      this.campos_ciclica = this.campos.map((campo) => ({ ...campo }));
      this.campos_ciclica.forEach((campo) => {
        campo.Visivel = false;
        if (campo.Valor) {
          campo.ValorPossivel = campo.Valor;
        }
        campo.Requerido = false;
        campo.Valor = "";
        if (this.inferido[campo.Nome.toLowerCase()]) {
          campo.Valor = this.inferido[campo.Nome.toLowerCase()];
          campo.Disabled = true;
        }
      });
      this.$router.push("/consultas/box/4").catch(() => {});
    },

    resetContextos() {
      this.contexto = [];
      this.resetaCodigoAgrupador();
    },

    async realizaConsulta() {
      this.resetContextos();
      var contexto_idx = this.contexto.length;
      this.contexto[contexto_idx] = {
        id: contexto_idx,
        obrigatorio: {},
        requisitados: {},
        resultados: {},
      };

      let tipoObrigatorio = this.produtosSelecionados[0].TipoProduto;
      let classificacaoBox = this.produtosSelecionados[0].Classificacao;
      if (tipoObrigatorio == "PF" && classificacaoBox != "Superbox") {
        this.$set(this.contexto[contexto_idx].obrigatorio, "BOXATRPF001", true);
      } else if (tipoObrigatorio == "PJ" && classificacaoBox != "Superbox") {
        this.$set(this.contexto[contexto_idx].obrigatorio, "BOXATRPJ001", true);
      } else if (tipoObrigatorio == "GEO" && classificacaoBox != "Superbox") {
        this.$set(
          this.contexto[contexto_idx].obrigatorio,
          "BOXATRGEO001",
          true
        );
      }
      this.produtosSelecionados.forEach((produto) => {
        this.$set(this.contexto[contexto_idx].requisitados, produto.Box, true);
      });
      // this.criaCodigoAgrupador();
      this.geraCodigoAgrupadorMetadados("consulta_box");
    },

    realizaConsultaCiclica() {
      this.campos = this.campos_ciclica;
      var contexto_idx = this.contexto.length;
      this.contexto[contexto_idx] = {
        id: contexto_idx,
        inferido: this.inferido,
        obrigatorio: {},
        requisitados: {},
        resultados: {},
      };

      let tipoObrigatorio = this.produtosSelecionadosCiclica[0].TipoProduto;
      var boxScroll = "";
      if (tipoObrigatorio == "PF") {
        boxScroll = "BOXATRPF001" + contexto_idx;
        this.$set(this.contexto[contexto_idx].obrigatorio, "BOXATRPF001", true);
      } else if (tipoObrigatorio == "PJ") {
        boxScroll = "BOXATRPJ001" + contexto_idx;

        this.$set(this.contexto[contexto_idx].obrigatorio, "BOXATRPJ001", true);
      } else {
        boxScroll = "BOXATRGEO001" + contexto_idx;
        this.$set(
          this.contexto[contexto_idx].obrigatorio,
          "BOXATRGEO001",
          true
        );
      }
      this.produtosSelecionadosCiclica.forEach((produto) => {
        this.$set(this.contexto[contexto_idx].requisitados, produto.Box, true);
      });
      setTimeout(() => {
        const boxElement = document.getElementById(boxScroll);
        boxElement.scrollIntoView({ block: "center", behavior: "smooth" });
      }, 500);
    },

    substituiProdutos(produtos) {
      this.produtos.forEach((produtoOriginal) => {
        produtos.forEach((produto) => {
          if (
            produtoOriginal.Box == produto.Box &&
            produtoOriginal.ApareceNosParametros == produto.ApareceNosParametros
          ) {
            produtoOriginal.Selecionado = produto.Selecionado;
          }
        });
      });
    },

    substituiProdutosCiclica(produtos) {
      this.produtos_ciclica.forEach((produtoOriginal) => {
        produtos.forEach((produto) => {
          if (
            produtoOriginal.Box == produto.Box &&
            produtoOriginal.ApareceNosParametros == produto.ApareceNosParametros
          ) {
            produtoOriginal.Selecionado = produto.Selecionado;
          }
        });
      });
    },

    selecionaInicial(opcao) {
      this.opcaoInicial = opcao;
    },

    async verificaPreferenciasSuperbox() {
      const preferenciaNome = "PortalOutbox_consultaSuperBox";

      const preferencia = await this.syncPreferenciaGeral(preferenciaNome);

      if (preferencia.Valor != null) {
        const valorGet = preferencia.Valor;
        const objetos = valorGet.split(";").map((item) => {
          const valores = item.split("=");
          const regex = /(\d+)$/;
          // let match = [];
          // match = valores[1].match(regex);
          if (valores[0] != valores[1]) {
            this.produtos.forEach((produto) => {
              if (produto.Box === valores[0]) {
                produto.Box = valores[1];
              }
            });
          }
        });
      }
    },

    ...mapActions(produtosStore, [
      "getProdutos",
      "criaCodigoAgrupador",
      "resetaCodigoAgrupador",
      "geraCodigoAgrupadorMetadados",
    ]),

    ...mapActions(userPreferenceStore, ["syncPreferenciaGeral"]),
    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },
  computed: {
    ...mapWritableState(produtosStore, {
      produtos: "produtos",
      codigo_agrupador: "codigo_agrupador",
      codigo_agrupador_box: "codigo_agrupador_box",
    }),

    produtosSelecionados() {
      return this.produtos.filter((produto) => {
        return produto.Selecionado;
      });
    },
    produtosSelecionadosCiclica() {
      return this.produtos_ciclica.filter((produto) => {
        return produto.Selecionado;
      });
    },
    camposComValor() {
      return this.produtos.filter((produto) => {
        return produto.Valor;
      });
    },
  },
  async mounted() {
    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);

    await this.getProdutos();
    this.liberaTela = true;
    this.campos = criaCampos.montaCampos(jsonProdutos);
    const store = sessionStore();
    store.$reset();
    this.verificaPreferenciasSuperbox();
  },

  beforeDestroy() {
    this.contexto = [];
  },

  watch: {
    opcaoInicial: function (newVal, oldVal) {
      this.produtos.forEach((produto) => {
        produto.Selecionado = false;
      });
      this.campos.forEach((campo) => {
        campo.Visivel = false;
        campo.Disabled = false;
        campo.Requerido = false;
      });
      this.contexto = [];
    },

    "produtosSelecionados.length": function (newVal, oldVal) {
      this.contexto = [];

      this.campos.forEach((campo) => {
        campo.Visivel = false;
        campo.Requerido = false;
        campo.Disabled = false;
      });
    },
  },
};
</script>

<style scoped>
.view-wrapper {
  margin-top: 10px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.absolute {
  display: none;
}

.view {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .view-wrapper {
    margin-top: 30px;
    gap: 30px;
  }
  .absolute {
    display: block;
    position: absolute;
    height: 30px;
    width: 50px;
    top: 20px;
    left: 100px;
  }
}
</style>
