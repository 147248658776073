<template>
  <div class="campos">
    <CamposConsulta
      data-aos="fade"
      :campos="campos_ciclica"
      :produtos="produtosSelecionados"
      :opcaoInicial="opcaoInicialSelecionada"
      :ciclica="true"
      @realizaConsultaCiclica="preencheInput"
    />
  </div>
</template>

<script>
import CamposConsulta from "@/components/Inputs/CamposConsulta";

export default {
  props: {
    campos_ciclica: Array,
    produtos_ciclica: Array,
  },
  data() {
    return {
      camposPreenchidos: {},
    };
  },
  components: {
    CamposConsulta,
  },
  methods: {
    preencheInput() {
      this.camposPreenchidos = {};
      var camposPreenchidos_ = this.campos_ciclica.filter(
        (campo) => campo.Valor
      );

      camposPreenchidos_.forEach((campoPreenchido) => {
        this.camposPreenchidos[campoPreenchido.Nome.toLowerCase()] =
          campoPreenchido.Valor;
      });
      this.$emit("realizaConsultaCiclica", this.camposPreenchidos);
      this.$router.push("/consultas/box/3");
    },
  },
  computed: {
    opcaoInicialSelecionada() {
      return Object.keys(this.produtosSelecionados[0].ApareceNosParametros)[0];
    },
    produtosSelecionados() {
      return this.produtos_ciclica.filter((produto) => {
        return produto.Selecionado;
      });
    },
  },
  mounted() {
    if (this.produtosSelecionados == 0) {
      this.$router.push("/consultas/box/0");
      window.location.reload();
    }
    this.analytics(
      "Campos - Cíclica",
      "Acessos",
      "Entrou no preenchimento de campos - Ciclica"
    );
  },
};
</script>

<style scoped>
.campos {
  margin-top: 10%;
}
</style>
