<template>
  <div class="produtos">
    <Produtos
      data-aos="fade"
      :produtos="produtosPossiveis"
      @substituiProdutos="emiteSubstitui"
    />
  </div>
</template>

<script>
import Produtos from "@/components/Produtos/Produtos.vue";
import Aos from "aos";
import "aos/dist/aos.css";
export default {
  props: {
    produtos_ciclica: Array,
    campos_ciclica: Array,
    inferido: Object,
  },
  components: {
    Produtos,
  },
  data() {
    return {};
  },
  methods: {
    emiteSubstitui(produtos_) {
      this.$emit("substituiProdutosCiclica", produtos_);
      this.$router.push("/consultas/box/5");
    },
  },
  computed: {
    produtosPossiveis() {
      var produtospossiveis = [];

      if (this.inferido["cep"]) {
        this.produtos_ciclica.forEach((produto) => {
          produto.Visivel = false;
          produto.Selecionado = false;
          if (produto.ApareceNosParametros["Geolocalização"]) {
            produto.Visivel = true;
            produtospossiveis.push(produto);
          }
        });
      }
      return produtospossiveis;
    },
  },
  mounted() {
    if (this.produtosPossiveis.length == 0) {
      this.$router.push("/consultas/box/0");
      window.location.reload();
    }
    this.analytics(
      "Produtos - Ciclica",
      "Acessos",
      "Entrou na seleção de produtos - Ciclica"
    );
  },
};
</script>

<style scoped>
.produtos {
  justify-self: center;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0 20px;
}
</style>
