<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Filtros</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-20 md-medium-size-50">
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <md-field>
                    <label for="pages">Registros por página</label>
                    <md-select
                      v-model="pagination.quantidadePorPagina"
                      name="pages"
                    >
                      <md-option
                        v-for="item in pagination.quantidadePorPaginaOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                        {{ item }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-25 md-medium-size-50 md-xsmall-size-100"
                >
                  <md-field>
                    <label for="periodoPreDefinido">Período a analisar</label>
                    <md-select
                      v-model="periodoPreDefinido"
                      name="periodoPreDefinido"
                      id="periodoPreDefinido"
                    >
                      <md-option value="mesAtual">Este mês</md-option>
                      <md-option value="semanaAtual">Esta semana</md-option>
                      <md-option value="mesAnterior">Mês passado</md-option>
                      <md-option value="semanaAnterior"
                        >Semana passada</md-option
                      >
                      <md-option value="diaAtual">Hoje</md-option>
                      <md-option value="custom">Customizado</md-option>
                    </md-select>
                  </md-field>
                </div>
                <div
                  class="md-layout-item md-size-35 md-medium-size-50 md-xsmall-size-100"
                  v-if="periodoPreDefinido != 'custom'"
                >
                  <md-field>
                    <md-input
                      disabled
                      :value="periodoDe + ' até ' + periodoAte"
                    ></md-input>
                  </md-field>
                </div>
                <div
                  class="md-layout-item md-size-35 md-small-size-50 md-xsmall-size-100"
                  v-if="periodoPreDefinido == 'custom'"
                >
                  <date-range-picker
                    @atualizouData="periodoFiltroAtualizado"
                    :periodoDe="periodoDeToDate()"
                    :periodoAte="periodoAteToDate()"
                  />
                </div>
                <div
                  class="md-layout-item md-size-25 md-medium-size-50 md-xsmall-size-100 conteudo-vertical-centralizado conteudo-horizontal-direita"
                >
                  <md-button
                    class="md-primary md-sm"
                    :disabled="dadosFiltroCarregando"
                    @click.native.prevent="recuperaHistorico"
                    >Filtrar<md-icon>update</md-icon></md-button
                  >
                </div>
                <div
                  class="md-layout-item md-size-15 md-medium-size-50 md-xsmall-size-100 conteudo-vertical-centralizado conteudo-horizontal-direita"
                >
                  <md-progress-spinner
                    v-if="dadosFiltroCarregando"
                    :md-diameter="30"
                    :md-stroke="2"
                    md-mode="indeterminate"
                  >
                  </md-progress-spinner>
                  <small v-if="mensagemCarregamentoFiltro">{{
                    mensagemCarregamentoFiltro
                  }}</small>
                </div>
                <div
                  class="md-layout-item md-size-50 md-medium-size-50 md-xsmall-size-100"
                  v-if="periodoPreDefinido == 'custom'"
                >
                  <span class="layout-warning"
                    >*Período máximo para o filtro Customizado é de 60 dias.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>
      <md-card class="agrup">
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">Consulta direta</h4>
        </md-card-header>
        <md-card-content>
          <div class="card-codigo-agrupador">
            <md-field class="md-layout-item md-size-30">
              <md-input
                v-model="InputCodigoAgrupador"
                :value="InputCodigoAgrupador"
                placeholder="Insira um Código"
                >Código Agrupador</md-input
              >
            </md-field>
            <md-button
              class="md-layout-item md-size-10 md-primary md-sm"
              :disabled="
                dadosFiltroCarregando || InputCodigoAgrupador.length < 1
              "
              @click.native.prevent="goToCode"
              >Consultar</md-button
            >
          </div>
        </md-card-content>
      </md-card>
      <md-card id="tabela_historico" style="z-index: 0">
        <md-card-content>
          <md-card class="conteudo-horizontal-centralizado" v-if="total == 0">
            <md-card-content> Nenhum resultado encontrado. </md-card-content>
          </md-card>

          <md-table
            v-if="tableData"
            :value="tableData"
            class="paginated-table table-striped table-hover"
          >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Data" md-sort-by="DataTransacaoBrz">{{
                item.DataTransacaoBrz | moment("DD/MM/YYYY HH:mm:ss")
              }}</md-table-cell>
              <md-table-cell md-label="Código">
                <router-link
                  class="codigo"
                  :to="`historico/${item.CodigoAgrupador}`"
                  >{{ item.CodigoAgrupador }}</router-link
                >
              </md-table-cell>
              <md-table-cell md-label="Consultas">
                <span v-for="(box, i) in item.Boxes" :key="i + box">
                  <span class="icones" v-if="!box.startsWith('BOXWRN')">
                    <md-tooltip>{{ nomes[box] }}</md-tooltip>
                    <span class="material-icons icon">{{ icones[box] }}</span>
                  </span>
                </span>
              </md-table-cell>
              <md-table-cell md-label="Parâmetros">
                <div
                  v-for="parametro in Object.keys(item.Parametros)"
                  :key="parametro"
                >
                  <span v-if="item.Parametros[parametro] != ''">
                    {{ parametro.toUpperCase() }}:
                  </span>
                  {{ formatarCPF(item.Parametros[parametro], parametro) }}
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Mostrando de <strong>{{ from + 1 }}</strong> a
              <strong>{{ to }}</strong> de um total de
              <strong id="total">{{ total }}</strong> registros
            </p>
          </div>
          <pagination
            @input="paginaAlterada"
            class="pagination-no-border pagination-success"
            v-model="pagination.paginaAtual"
            :per-page="pagination.quantidadePorPagina"
            :total="total"
            v-if="!disablePagination"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination, DateRangePicker } from "@/components";
import { historicoService } from "@/services";
import { utilitarios } from "@/helpers";
import jsonbox from "@/helpers/produtosControle.json";
import { userPreferenceStore } from "@/stores/userPreference";
import { sessionStore } from "@/stores/session";
import { produtosStore } from "@/stores/produtos";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapActions, mapWritableState } from "pinia";
export default {
  components: {
    Pagination,
    DateRangePicker,
  },

  computed: {
    ...mapWritableState(sessionStore, {
      historicoFiltros: "historicoFiltros",
      historicoResultados: "historicoResultados",
    }),

    ...mapWritableState(produtosStore, {
      modelos: "modelos",
    }),

    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
    }),

    to() {
      let resultadoTo = this.from + this.pagination.quantidadePorPagina;
      if (this.total < resultadoTo) {
        resultadoTo = this.total;
      }
      return resultadoTo;
    },
    from() {
      if (this.total == 0) {
        return -1;
      }
      var resultadoFrom =
        this.pagination.quantidadePorPagina * (this.pagination.paginaAtual - 1);
      return resultadoFrom;
    },
  },
  data() {
    return {
      periodoDe: "",
      periodoAte: "",
      periodoPreDefinido: "",
      total: 0,
      pagination: {},
      mensagemCarregamentoFiltro: "",
      dadosFiltroCarregando: false,
      tableData: [],
      disablePagination: false,
      icones: [],
      nomes: [],
      listaRotasModelos: [],
      InputCodigoAgrupador: "",
    };
  },
  methods: {
    formatarCPF(valor, parametro) {
      if (parametro == "cpf") {
        valor = valor.replace(/\D/g, "");

        valor = valor.replace(/^(\d{3})(\d)/, "$1.$2");
        valor = valor.replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3");
        valor = valor.replace(/\.(\d{3})(\d)/, ".$1-$2");

        if (this.ocultarDados) {
          return valor.replace(
            /(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/,
            "***.$2.$3-**"
          );
        } else {
          return valor;
        }
      } else if (parametro == "cnpj") {
        valor = valor.replace(/\D/g, "");
        valor = valor.replace(/^(\d{2})(\d)/, "$1.$2");
        valor = valor.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        valor = valor.replace(/\.(\d{3})(\d)/, ".$1/$2");
        valor = valor.replace(/(\d{4})(\d)/, "$1-$2");
        if (this.ocultarDados) {
          valor = valor.replace(
            /(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})\-(\d{2})/,
            (match, p1, p2, p3, p4, p5) => `${p1}.***.***/****-${p5}`
          );
        }
      }
      return valor;
    },

    periodoDeToDate: function () {
      var resultado = this.$moment(this.periodoDe, "DD/MM/YYYY").toDate();
      return resultado;
    },
    periodoAteToDate: function () {
      var resultado = this.$moment(this.periodoAte, "DD/MM/YYYY").toDate();
      return resultado;
    },

    async periodoFiltroAtualizado(a) {
      this.periodoDe = a.periodoDe;
      this.periodoAte = a.periodoAte;
    },

    async paginaAlterada(numeroPagina) {
      this.disablePagination = true;
      if (this.historicoFiltros.pagina != this.pagination.paginaAtual) {
        await this.recuperaHistorico();
      }
      this.disablePagination = false;
    },

    preenchePeriodoInicial() {
      this.periodoDe = this.$moment()
        .locale("pt-BR")
        .startOf("week")
        .format("DD/MM/YYYY");
      this.periodoAte = this.$moment().format("DD/MM/YYYY");
    },
    salvarEstado(objeto, resultado) {
      this.historicoFiltros = objeto;
      this.historicoResultados = resultado;
    },
    externalizaParametros() {
      const parametrosUteis = ["cpf", "cnpj", "cep"];
      this.tableData.forEach((consulta) => {
        consulta.Boxes = [];
        consulta.Consultas.forEach((box) => {
          consulta.Boxes.push(box.Parametros.cod_ref);
          if (!box.CodigoTransacaoCiclicaPai) {
            consulta.Parametros = {};
            Object.keys(box.Parametros).forEach((parametro) => {
              if (parametrosUteis.indexOf(parametro) != -1) {
                consulta.Parametros[parametro] = box.Parametros[parametro];
              }
            });
          }
        });
      });
    },
    async recuperaHistorico() {
      this.dadosFiltroCarregando = true;

      const periodoDeFormatado = this.$moment(
        this.periodoDe,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      const periodoAteFormatado = this.$moment(
        this.periodoAte,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      const objetoHistorico = {
        periodoDeBrz: periodoDeFormatado,
        periodoAteBrz: periodoAteFormatado,
        quantidadeRegistros: this.pagination.quantidadePorPagina,
        pagina: this.pagination.paginaAtual,
        ordenacao: "dataDesc",
      };

      await this.encontraTotal();

      const query = new URLSearchParams(objetoHistorico);
      this.tableData = await historicoService.recuperaDadosHistorico(
        query.toString()
      );

      this.externalizaParametros();

      objetoHistorico.total = this.total;
      objetoHistorico.periodoPreDefinido = this.periodoPreDefinido;

      for (const data of this.tableData) {
        let todosComecamComBoxsup = true;

        for (const elemento of data.Boxes) {
          if (!elemento.startsWith("BOXSUPPF")) {
            todosComecamComBoxsup = false;
            break;
          }
        }

        if (todosComecamComBoxsup) {
          data.Boxes.push("BOXSUPPF009");
        }

        if (
          data.Boxes.includes("BOXKYC001") &&
          data.Boxes.includes("BOXKYC002") &&
          data.Boxes.includes("BOXKYC003") &&
          data.Boxes.includes("BOXKYC004")
        ) {
          data.Boxes = data.Boxes.filter(
            (box) =>
              box !== "BOXKYC002" && box !== "BOXKYC003" && box !== "BOXKYC004"
          );
        }
      }

      this.salvarEstado(objetoHistorico, [...this.tableData]);
      this.dadosFiltroCarregando = false;

      await this.recuperaListaModelos();
    },

    goToCode() {
      this.InputCodigoAgrupador = this.InputCodigoAgrupador.replace(/\s/g, "");

      this.$router.push(`/consultas/historico/${this.InputCodigoAgrupador}`);
    },

    async encontraTotal() {
      const periodoDeFormatado = this.$moment(
        this.periodoDe,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      const periodoAteFormatado = this.$moment(
        this.periodoAte,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      const objetoHistorico = {
        periodoDeBrz: periodoDeFormatado,
        periodoAteBrz: periodoAteFormatado,
      };

      const query = new URLSearchParams(objetoHistorico);
      this.total = await historicoService.contagemPeriodo(query.toString());
    },

    recuperaListaModelos() {
      let objetos = [];
      for (let obj1 of this.tableData) {
        for (let modelo of this.modelos) {
          for (let box of obj1.Boxes) {
            if (box == modelo.Valor) {
              objetos.push(modelo);
            }
          }
        }
      }
      this.listaRotasModelos = objetos;

      this.listaRotasModelos.forEach((modelo) => {
        this.icones[modelo.Valor] = "speed";
        this.nomes[modelo.Valor] = modelo.Descricao;
      });
    },

    setaIcones() {
      jsonbox.produtosObrigatorios.forEach((box) => {
        this.icones[box.Box] = box.Icone;
        this.nomes[box.Box] = box.Nome;
      });

      jsonbox.produtos.forEach((box) => {
        this.icones[box.Box] = box.Icone;
        if (box.Nome.includes("Bloco")) {
          this.nomes[box.Box] = box.Nome.split("- ")[1];
        } else {
          this.nomes[box.Box] = box.Nome;
        }
      });
    },

    ...mapActions(produtosStore, ["getModelos"]),

    ...mapActions(userPreferenceStore, ["syncPreferenciaOcultarDados"]),
    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },
  async created() {
    this.analytics(
      "Lista Histórico",
      "Acessos",
      "Página inicial de históricos acessada."
    );

    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);

    await this.getModelos();

    if (Object.keys(this.historicoFiltros).length == 0) {
      this.periodoPreDefinido = "semanaAtual";
      this.setaIcones();
      this.preenchePeriodoInicial();
      this.pagination = {
        quantidadePorPagina: 10,
        paginaAtual: 1,
        quantidadePorPaginaOptions: [5, 10, 25],
      };
      await this.recuperaHistorico();
    } else {
      this.setaIcones();
      this.dadosFiltroCarregando = true;
      this.periodoPreDefinido = this.historicoFiltros.periodoPreDefinido;
      this.total = this.historicoFiltros.total;
      this.pagination = {
        quantidadePorPagina: this.historicoFiltros.quantidadeRegistros,
        paginaAtual: this.historicoFiltros.pagina,
        quantidadePorPaginaOptions: [5, 10, 25],
      };
      this.tableData = this.historicoResultados;
      this.dadosFiltroCarregando = false;
      await this.recuperaListaModelos();
    }

    this.ocultarDados = await this.syncPreferenciaOcultarDados();
  },

  watch: {
    async periodoPreDefinido(periodoSelecionado) {
      this.analytics(
        `Troca de periodo pré definido para ${periodoSelecionado}`,
        "Troca de variável",
        "Houve uma troca no periodo selecionado do histórico"
      );

      switch (periodoSelecionado) {
        case "mesAtual":
          this.periodoDe = "01/" + this.$moment().format("MM/YYYY");
          this.periodoAte = this.$moment().format("DD/MM/YYYY");

          break;
        case "mesAnterior":
          var dataMesAnterior = this.$moment()
            .locale("pt-BR")
            .subtract({ months: 1 });
          this.periodoDe = "01/" + dataMesAnterior.format("MM/YYYY");
          this.periodoAte = dataMesAnterior.endOf("month").format("DD/MM/YYYY");

          break;
        case "semanaAtual":
          this.periodoDe = this.$moment()
            .locale("pt-BR")
            .startOf("week")
            .format("DD/MM/YYYY");
          this.periodoAte = this.$moment().format("DD/MM/YYYY");

          break;
        case "semanaAnterior":
          var dataSemanaAnterior = this.$moment()
            .locale("pt-BR")
            .subtract({ weeks: 1 });
          this.periodoDe = dataSemanaAnterior
            .startOf("week")
            .format("DD/MM/YYYY");
          this.periodoAte = dataSemanaAnterior
            .endOf("week")
            .format("DD/MM/YYYY");

          break;
        case "diaAtual":
          this.periodoDe = this.$moment().format("DD/MM/YYYY");
          this.periodoAte = this.$moment().format("DD/MM/YYYY");

          break;
        case "custom":
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.md-table-cell-container {
  flex-direction: column;
  justify-content: center;
}
.icon {
  cursor: pointer;
  padding: 2.5px;
}
.agrup {
  z-index: 0;
}
.layout-warning {
  position: relative;
  bottom: 10px;
  font-size: 11px;
  opacity: 0.5;
}
</style>
