<template>
  <div class="produtos">
    <div></div>
    <Produtos
      data-aos="fade"
      :produtos="produtosVisiveis"
      :opcao_inicial="opcaoInicialSelecionada"
      @substituiProdutos="emiteSubstitui"
    />
  </div>
</template>

<script>
import Produtos from "@/components/Produtos/Produtos.vue";
import Aos from "aos";
import "aos/dist/aos.css";

export default {
  props: {
    produtos: Array,
    opcaoInicialSelecionada: String,
  },
  components: {
    Produtos,
  },
  methods: {
    emiteSubstitui(produtos_) {
      this.analytics(
        "Fechou o popup com sucesso",
        "Avisos",
        "Houve a confirmação no popup e possível re-seleção de produtos."
      );
      this.$emit("substituiProdutos", produtos_);
      this.$router.push("/consultas/box/2");
    },
  },
  mounted() {
    this.analytics(
      "Produtos",
      "Acessos",
      "Entrou na página de seleção de produtos"
    );
    this.produtos.forEach((produto) => {
      produto.Visivel = false;
      if (produto.ApareceNosParametros[this.opcaoInicialSelecionada]) {
        produto.Visivel = true;
      }
    });
    Aos.init({ duration: 800, once: true });

    var x = false;
    this.produtos.forEach((produto) => {
      produto.Visivel ? (x = true) : "";
    });
    if (!this.opcaoInicialSelecionada) {
      window.location.href = "/consultas/box/0";
    }
  },
  computed: {
    produtosVisiveis() {
      return this.produtos.filter((produto) => {
        return produto.Visivel;
      });
    },
  },
};
</script>

<style scoped>
.produtos {
  justify-self: center;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0 10px;
}
</style>
